import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../../components/layout';

import PrivateRoute from './../../../components/s4n/AccountManagement/PrivateRoute';
import Calendar from './../../../components/s4n/AccountManagement/Calendar';

const Component = () => {
  return (
    <Layout className={`dashboard_calendar__page`}>
       
       <PrivateRoute component={Calendar} disableCheckAccess={true} />

    </Layout>
  )
}

export default Component;