// @see: https://fullcalendar.io/docs/react
// @see: https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx
// @see: https://fullcalendar.io/docs/google-calendar

// npm install @fullcalendar/react @fullcalendar/daygrid @fullcalendar/timegrid @fullcalendar/interaction @fullcalendar/core @fullcalendar/google-calendar

// Identyfikator kalendarza
// hjqte51tutqac5eof176nhcfd4@group.calendar.google.com

import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from "gatsby-plugin-intl"
// import axios from "axios"
// import Link from './../Link'

import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
// import { 
//   // INITIAL_EVENTS, 
//   createEventId 
// } from './event-utils'

import googleCalendarPlugin from '@fullcalendar/google-calendar';

import localePl from '@fullcalendar/core/locales/pl';
import localeEn from '@fullcalendar/core/locales/en-gb';
// import './main.scss'


const CALENDAR_REMOVE_OTHERS_EVENTS = false;
const GOOGLE_CALENDAR_EVENT_COLORS = [
  `#D50000`,
  `#E67C73`,
  `#F4511E`,
  `#F6BF26`,
  `#33B679`,
  `#0B8043`,
  `#039BE5`,
  `#3F51B5`,
  `#7986CB`,
  `#8E24AA`,
  `#616161`,
]


class Component extends React.Component {

  state = {
    loading: false,
    weekendsVisible: true,
    currentEvents: [],
    event_types: {},
  }

  constructor(props) {
    super(props);

    const { intl } = props;
    const t = intl.formatMessage;

    this.intl = intl;

    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    // this.getEvents();
  }

  getEvents = async () => {
    const intl = this.intl;

    this.setState({ loading: true });

    // const { data: event_types } = await axios.get(`${process.env.SOFT4NET_SOURCE_BASE_URL}/soft4net/field_storage_config_paragraph_field_type`);
    const event_types = {
      typ1: `Typ 1_#f00`,
      typ2: `Typ 2_#0f0`,
      typ3: `Typ 2_#00f`,
    }

    await this.setState({ event_types: event_types });
// console.log(this.state.event_types)
    let events = [];

    for (let type in event_types) {
      console.log(type)

      // events.push({
      //   id: harmonogramLectureFulldata.id,
      //   start: harmonogramLectureFulldata.attributes.field_date_from, // new Date().toISOString().replace(/T.*$/, '')
      //   end: harmonogramLectureFulldata.attributes.field_date_to,
      //   title: eventType.title,
      //   url: eventType.url,
      //   classNames: eventType.classNames,
      //   color: eventType.color,
      //   allDay: eventType.allDay,
      // })
    }

    this.setState({
      loading: false,
      currentEvents: events,
    });

  }

  render() {
    const intl = this.intl;

    const userDisplayName = this.props?.user?.user_profile?.attributes?.display_name || null;
    if (null === userDisplayName) {
      return null;
    }

    const calendarRef = this.calendarRef;

    return (
      <div className='demo-app'>
        {/* {this.renderSidebar()} */}
        <div className='demo-app-main'>
          
          <p className="has-text-align-center has-medium-font-size">
            <strong>{this.intl.locale === `pl` ? `Grafik pracownika:` : `Employee's schedule:`}</strong>
          </p>

          <FullCalendar
            ref={this.calendarRef}
            locale={intl.locale === `pl` ? localePl : localeEn}
            plugins={[
              dayGridPlugin, timeGridPlugin, interactionPlugin, 
              googleCalendarPlugin,
            ]}

            googleCalendarApiKey={process.env.SOFT4NET_GOOGLE_CALENDAR_API_KEY}
            events={{
              googleCalendarId: process.env.SOFT4NET_GOOGLE_CALENDAR_ID,
              className: `gcal-event`, // an option!
              // backgroundColor: `#f00`,
              // url: null,
            }}
            // initialEvents={this.state.currentEvents} // alternatively, use the `events` setting to fetch from a feed
              // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
            // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed

            // @see: https://fullcalendar.io/docs/eventsSet
            eventsSet={(events) => {

              if (CALENDAR_REMOVE_OTHERS_EVENTS) {
                events.forEach(event => {
                  if (!event.title.includes(userDisplayName)) {
                    event.remove();
                  }
                });
              }

//               events.forEach(event => {
// // console.log(event);
// // console.log(event.extendedProps);
//                 // event.setUrl(``);
//                 // event.setProp(`url`, ``);
//                 // event.setExtendedProp(`url`, ``); // {location: undefined, description: undefined}
//                 // event.setProp(`backgroundColor`, `#ff0000`); // CAUSES INFINITE LOOP!!!
//               });

              // @see: https://fullcalendar.io/docs/react
              // let calendarApi = this.calendarRef.current.getApi();
              // calendarApi.next()

            }}
            // getEvents={event => {
            //   console.log(`getEvents: `, event)
            // }}

            // @see: https://codepen.io/arshaw/pen/xxEqexm?editors=0010
            // bypass set event backgroundColor
            eventDidMount={(arg) => {
// console.log(arg)
              const { event } = arg;
// console.log(event)
              let title = event.title;
              let colorIndex = 1;
              let title_colorIndex = null;
              if (event.title.includes(`^`)) {
                title_colorIndex = event.title.split(`^`);
// console.log(title_colorIndex)
                title = title_colorIndex[0];
                colorIndex = parseInt(title_colorIndex[1]);
              }

              setTimeout(function() {
                event.setProp(`title`, title);
                // event.setProp(`backgroundColor`, GOOGLE_CALENDAR_EVENT_COLORS[colorIndex - 1]);
              })
            }}

            headerToolbar={{
              left: 'title',
              center: '',
              right: 'prev,next today dayGridMonth,timeGridWeek,timeGridDay'
              // right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            // editable={true}
            // selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={this.state.weekendsVisible}

            // select={this.handleDateSelect}
            // eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            


            // dateClick={this.handleDateClick}
            // events={this.state.currentEvents}

            dayMaxEvents={true} // allow "more" link when too many events
            // moreLinkText={intl.formatMessage({ id: `soft4net_more` })}
            moreLinkText={``}
//             moreLinkContent={(num) => {
// console.log(num)
//               return num;
//             }}
            themeSystem={`bootstrap`}
            // eventTimeFormat
            // eventColor
            // eventBackgroundColor="#0f0"



          />



          {/* <div className="calendar-legend">
            {Object.keys(this.state.event_types).map(eventTypeKey => {
              const fieldTypeAllowedValue = this.state.event_types[eventTypeKey];
              const nameAndColor = fieldTypeAllowedValue.split(`_`);

              const eventType = {
                type_title: nameAndColor[0],
                color: nameAndColor[1],
                classNames: `harmonogram` === eventTypeKey ? `harmonogram` : `custom-event-${eventTypeKey}`,
              }

              return (
                <p>
                  <i className="fas fa-circle" style={{color: eventType.color}}></i> 
                  <span className={eventType.classNames}>{eventType.type_title}</span>
                </p>
              );
            })}
          </div> */}

        </div>
      </div>
    )
  }

/*
  renderSidebar() {
    return (
      <div className='demo-app-sidebar'>
        <div className='demo-app-sidebar-section'>
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>
        <div className='demo-app-sidebar-section'>
          <label>
            <input
              type='checkbox'
              checked={this.state.weekendsVisible}
              onChange={this.handleWeekendsToggle}
            ></input>
            toggle weekends
          </label>
        </div>
        <div className='demo-app-sidebar-section'>
          <h2>All Events ({this.state.currentEvents.length})</h2>
          <ul>
            {this.state.currentEvents.map(renderSidebarEvent)}
          </ul>
        </div>
      </div>
    )
  }
*/

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible
    })
  }

  handleDateSelect = (selectInfo) => {
    let title = prompt('Please enter a new title for your event')
    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }
  }


  handleEventClick = (clickInfo) => {
    // @see: https://fullcalendar.io/docs/eventClick
    clickInfo.jsEvent.preventDefault(); // don't let the browser navigate

    // console.log(clickInfo.event)
    alert(clickInfo.event.title)
    // alert(clickInfo.event.start)
    // alert(clickInfo.event.extendedProps.description)


    // if (clickInfo.event.url) {
    //   window.open(info.event.url);
    // }

    // clickInfo.event.setProp(`url`, ``);
    return false;

    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }

    clickInfo.event.remove()
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events
    })
  }

  handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  }

}

function renderEventContent(eventInfo) {
// console.log(eventInfo)
  return (
    <>
      {/* <b>{eventInfo.timeText}</b> */}
      {/* <i>{eventInfo.event.title}</i> */}
      {/* <Link to={eventInfo.event.url} className="btn btn-primary w-100">{eventInfo.event.title}</Link> */}
      <button className="btn btn-primary w-100">{eventInfo.event.title}</button>
    </>
  )
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id}>
      <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
      <i>{event.title}</i>
    </li>
  )
}

// export default injectIntl(Component)

const mapStateToProps = state => {
  return {
      user: state.shopReducers.user,
  }
};

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));






/*

document.addEventListener('DOMContentLoaded', function() {
    var Calendar = FullCalendar.Calendar;
    var Draggable = FullCalendar.Draggable;
  
    var containerEl = document.getElementById('external-events');
    var calendarEl = document.getElementById('calendar');
    var checkbox = document.getElementById('drop-remove');
  
    // initialize the external events
    // -----------------------------------------------------------------
  
    new Draggable(containerEl, {
      itemSelector: '.fc-event',
      eventData: function(eventEl) {
        return {
          title: eventEl.innerText
        };
      }
    });
  
    // initialize the calendar
    // -----------------------------------------------------------------
  
    var calendar = new Calendar(calendarEl, {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
      initialView: 'dayGridMonth',
      editable: true,
      droppable: true, // this allows things to be dropped onto the calendar
      drop: function(info) {
        // is the "remove after drop" checkbox checked?
        if (checkbox.checked) {
          // if so, remove the element from the "Draggable Events" list
          info.draggedEl.parentNode.removeChild(info.draggedEl);
        }
      }
    });
  
    calendar.render();
});

*/
